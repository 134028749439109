@media (max-width: 480px) {
  .modal-content {
    max-width: 90%;
    max-height: 80vh;
  }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
  }
  
  .close-button:hover {
    color: #f44336;
  }
  
  h2 {
    margin-top: 0;
    color: #333;
    font-size: 24px;
  }
  
  .address-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .address {
    color: #666;
    font-size: 14px;
    margin: 0;
  }
  
  .open-maps-button {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .open-maps-button:hover {
    background-color: #3367D6;
  }
  
  .open-maps-button svg {
    margin-right: 5px;
  }
  
  .rating {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .stars {
    color: #ffc107;
    margin-right: 5px;
  }
  
  .rating-number {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .reviews {
    color: #666;
    font-size: 14px;
  }
  
  .section {
    margin-bottom: 25px;
  }
  
  h3 {
    color: #444;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .services-list {
    list-style-type: none;
    padding: 0;
  }
  
  .services-list li {
    margin-bottom: 5px;
    color: #555;
    font-size: 14px;
  }
  
  .gallery-container {
    position: relative;
    margin-bottom: 20px;
  }
  
  .gallery {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .gallery::-webkit-scrollbar {
    display: none; /* WebKit */
  }
  
  .gallery img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 5px;
    transition: opacity 0.3s ease;
  }
  
  .gallery img.active {
    opacity: 1;
  }
  
  .gallery-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .gallery-nav:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .gallery-nav.prev {
    left: 10px;
  }
  
  .gallery-nav.next {
    right: 10px;
  }
  
  .book-now-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .book-now-button:hover {
    background-color: #128C7E;
  }
  
  .book-now-button svg {
    margin-right: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .modal-content {
      padding: 20px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    h3 {
      font-size: 16px;
    }
  
    .gallery img {
      height: 200px;
    }
  
    .book-now-button {
      font-size: 16px;
    }
  
    .address-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .open-maps-button {
      margin-top: 10px;
    }
  }
  