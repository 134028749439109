body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin: 20px 0;
  text-align: center;
}

.location-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.location-tabs button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
}

.location-tabs button.active {
  background-color: #4CAF50;
  color: white;
}

.location-tabs button:hover {
  background-color: #ddd;
}

.filter-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-wrapper-2 {
  display: inline-flex;
  align-items: center;
}

.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #6e79d6;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #535db3;
}

.checkbox-wrapper-2 span {
  margin-left: 10px;
  font-size: 14px;
}

.parlor-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.parlor-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
}

.parlor-card:hover {
  transform: translateY(-5px);
}

.parlor-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.parlor-info {
  padding: 12px;
}

.parlor-card h2 {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: bold;
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.stars {
  color: #ffc107;
  margin-right: 4px;
}

.reviews {
  color: #666;
  font-size: 14px;
}

.address {
  margin: 0 0 8px 0;
  font-size: 14px;
  color: #666;
}

.category {
  display: inline-block;
  padding: 4px 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
}

.open-status {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.gallery-container {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.gallery img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.gallery-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.gallery-nav.prev {
  left: 10px;
}

.gallery-nav.next {
  right: 10px;
}

.gallery-nav:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.image-counter {
  text-align: center;
  margin-top: 10px;
}

button.close-modal {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

button.close-modal:hover {
  background-color: #d32f2f;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .parlor-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .location-tabs {
    flex-wrap: wrap;
  }

  .location-tabs button {
    margin-bottom: 10px;
  }

  .modal-content {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .parlor-grid {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 20px;
  }

  .checkbox-wrapper-2 span {
    font-size: 12px;
  }

  .parlor-card h2 {
    font-size: 14px;
  }

  .address, .reviews {
    font-size: 12px;
  }

  .category {
    font-size: 10px;
  }

  .open-status {
    font-size: 10px;
    padding: 3px 6px;
  }
}
